import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import TableCarousel from '../Components/TableCarousel';
import "./quests.scss";

import GameEVM from '../images/portal/game-evm.webp';
import GameChooChoo from '../images/portal/game-choochoo.webp';
import GameSugarRush from '../images/portal/game-sugarrush.webp';
import GamePepeKingdom from '../images/portal/game-pepekingdom.webp';
import GameCandyDefense from '../images/portal/game-candydefense.webp';
import GameKittySlash from '../images/portal/game-kittyslash.webp';
import GalaxeLogo from '../images/galaxeLogo.png';
import UsergroupIcon from '../images/icon-usergroup.png';

export default function Quests() {

  const { isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isConnected) {
      navigate('/login');
    }
  }, [isConnected, navigate]);

  const featuredData = {
    headerTitle: 'Featured Quests',
    headerTitleColor: '#FFCC5A',
    // headerSubTitle: 'Milestone',
    // headerSubTitle2: 'Points/Max',
    tableType: 'main',
    tableSubHeader: 'Begin your Deez Journey now',
    data: [
      {
        textColor: '',
        icon: GalaxeLogo,
        title: 'Voyager\'s NFT Expedition',
        titleClassName: 'strike-through',
        subTitle: 'Follow and retweet on @Zkcandyhq on X [Twitter], join our Telegram and Discord, and mint an NFT in Sugar Rush to earn your Voyager Pass NFT and Candy Points.',
        milestone: 140,
        button: [
          {
            type: 'disabled',
            icon: GalaxeLogo,
            buttonText: 'Ended',
            link: 'https://app.galxe.com/quest/VFwxFFsaFfESCUC6iHfzp9/GCAAutxG3i',
            target: '_blank',
            disabled: true
          },
        ]
      },
      {
        icon: GalaxeLogo,
        textColor: '',
        title: 'Points Frenzy Challenge',
        subTitle: 'Play our exciting ZKcandy games like Sugar Rush, EVM Warfare, and Choo Choo Spirits to climb the leaderboards and earn Candy Points!',
        milestone: 140,
        button: [
          {
            type: '',
            icon: GalaxeLogo,
            buttonText: 'Join',
            link: 'https://app.galxe.com/quest/VFwxFFsaFfESCUC6iHfzp9/GC4obtx5bc',
            target: '_blank',
          },
        ]
      },
      // {
      //   textColor: '#FFCC5A',
      //   icon: UsergroupIcon,
      //   title: 'ZKcandy Homie',
      //   subTitle: 'Refer a friend who earns 1,000 Quests points this season',
      //   milestone: 200,
      //   milestoneDesc: '/Friend',
      //   point: 0,
      //   maxPoint: 5000,
      //   button: [
      //     {
      //       type: 'secondary',
      //       buttonText: 'Referral Code',
      //       link: '/my-points'
      //     },
      //   ]
      // }
    ]
  };

  const seasonalData = {
    headerTitle: 'Seasonal Quests',
    headerTitleColor: '',
    headerSubTitle: 'Milestone',
    headerSubTitle2: 'Points/Max',
    tableType: '',
    data: [
      {
        icon: UsergroupIcon,
        textColor: '#FFCC5A',
        title: 'Questy Bestie',
        subTitle: 'Refer a friend who earns 5,000 Quests points this season',
        milestone: 500,
        milestoneDesc: '/Friend',
        button: [
          {
            type: 'secondary',
            buttonText: 'Referral Code',
            link: '/my-points'
          },
        ]
      },
      {
        icon: UsergroupIcon,
        textColor: '#FFCC5A',
        title: 'Questy Bestie',
        subTitle: 'Refer a friend who earns 17,000 Quests points this season',
        milestone: 1500,
        milestoneDesc: '/Friend',
        button: [
          {
            type: 'secondary',
            buttonText: 'Referral Code',
            link: '/my-points'
          },
        ]
      },
      {
        icon: UsergroupIcon,
        textColor: '#FFCC5A',
        title: 'Regal Domination',
        subTitle: 'Complete the collection of all 8 Sugar Rush Candy NFTs.',
        milestone: 100,
        milestoneDesc: '/Candy NFT',
        button: [
          {
            type: 'secondary',
            buttonText: 'Referral Code',
            link: '/my-points'
          },
        ]
      },
    ]
  };

  const gameContent = {
    type: 2,
    cardname: 'game',
    subTitle: 'Play To Airdrop',
    title: '',
    descTop: 'Level up your digital empire, mint your wins,',
    descBottom: 'and flex your crypto-savvy skills. ',
    details: [
      {
        name: 'Sugar \n Rush',
        desc: '',
        img: GameSugarRush,
        link: 'https://sugarrush-testnet.zkcandy.io/',
      },
      {
        name: 'EVM Warfare',
        desc: '',
        img: GameEVM,
        link: 'https://evmwarfare.com/',
      },
      {
        name: 'Choo Choo Spirit',
        desc: '',
        img: GameChooChoo,
        link: 'https://choochoospirits.io/',
      },
      {
        name: 'Pepe Kingdom',
        desc: '',
        img: GamePepeKingdom,
        link: 'https://t.me/pepe_kingdom_bot',
      },
      {
        name: 'Candy Defense',
        desc: '',
        img: GameCandyDefense,
        link: 'https://t.me/CandyDefense_bot',
      },
      {
        name: 'Kitty Slash',
        desc: '',
        img: GameKittySlash,
        link: 'https://t.me/kittyslash_bot',
      },
    ],
    caption: 'Blockchain? \n More like game-on!',
  };

  const CardWithHeader = ({ cardWithHeader }) => (
    <div id={cardWithHeader.cardname} className={`portal-header-frame type-${cardWithHeader.type} card-${cardWithHeader.cardname}`}>
      <div className="portal-frame">
        <div className="sub-title text-center">{cardWithHeader.subTitle}</div>
        <div className="title text-center">{cardWithHeader.title}</div>

        <div className="text-desc-top text-center">{cardWithHeader.descTop}</div>
        <div className="text-desc-bottom text-center">{cardWithHeader.descBottom}</div>


        <div className="cards flex flex-wrap justify-center -mx">
          {cardWithHeader.details.map((card, index) => (
            <div key={index} className='w-1/2 md:w-1/3 lg:w-1/3 px-3'>
              <div className="box small" rel="noopener noreferrer">
                <a className="box-thumb" href={card.link} target="_blank">
                  <img src={card.img} alt="" className="" />
                </a>
                {cardWithHeader.cardname === 'guide' ? (
                  <div className="box-desc">
                    <a className="title" href={card.link} target="_blank">{card.name}</a>
                    {card.languages != null && (
                      <div className='languages flex flex-wrap'>
                        {card.languages.map((lang, langIndex) => (
                          <a href={lang.link} className='language border-solid border-2 border-white me-2 mb-2 px-2' key={langIndex}>
                            {lang.lang}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <a className="box-desc" href={card.link} target="_blank">
                    <div className="title">{card.name}</div>
                    <div className="sub-title">{card.desc}</div>
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
        {cardWithHeader.type === 2 && (<div className="caption flex text-center justify-center">{cardWithHeader.caption}</div>)}
      </div>
    </div>
  );

  return (
    <main className="quests tracking-tighter text-white font-normal leading-7">
      <div className="text-banner flex justify-center">
        <div className="container flex justify-center text-center">
          Compete for 100 mil Candy Points in Season Zero
        </div>
      </div>

      <div className="text-season flex justify-center items-center">
        <div className="text-[#FFCC5A]">Season Zero</div>
      </div>

      <div className=" pb-10">
        <div className="container m-auto">
          <TableCarousel tableData={featuredData} />
          {/* <TableCarousel tableData={seasonalData} /> */}
          <CardWithHeader cardWithHeader={gameContent} />
        </div>
      </div>
    </main>
  );
}