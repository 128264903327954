import React, { useState, useRef, useEffect } from 'react';
import Logo from '../images/logo.png';  // Add this import
import Menu from '../images/menu.png';  // Add this import
import { Link, useLocation } from "react-router-dom";
import { useWallet } from '../Context/WalletContext';  // Import the WalletContext hook

export default function Header() {
  const { walletAddress, isConnected, connectWallet } = useWallet();  // Access the wallet context

  const location = useLocation();
  const showMenuButton = location.pathname !== '/' && location.pathname !== '/login';
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown menu

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Attach and clean up the event listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="header flex justify-center">
      <div className="container flex items-center justify-between">
        <div className="flex items-center">
          <Link to="/" className="logo">
            <img
              src={Logo}
              alt="logo"
              className=""
            />
          </Link>
          {showMenuButton && (
            <div className="page-title">
              {location.pathname === '/my-points' ? "My Points" : location.pathname.slice(1)}
            </div>
          )}
        </div>
        <div className="flex items-center">
          <div className={!showMenuButton ? 'wallet-button home' : 'hidden md:block'}>
            {!isConnected && showMenuButton && (
              <button className='btn' onClick={connectWallet}>Connect Wallet</button>
            )}
            {isConnected && (
              <w3m-button />
            )}
          </div>

          {showMenuButton && (
            <div className="relative inline-block text-left">
              <button
                onClick={toggleDropdown}
                className="px-4 py-2"
              >
                <div className="btn-menu">
                  <img
                    src={Menu}
                    alt="logo"
                    className=""
                  />
                </div>
              </button>
              {isOpen && (
                <div
                  ref={dropdownRef}
                  className="dropdown absolute right-0 mt-2 origin-top-right rounded-md shadow-lg"
                >
                  <div className="p-1">
                    <div>
                      <Link onClick={toggleDropdown} to="/" className="block px-4 py-3 text-white w-full text-left">Home</Link>
                    </div>
                    <div>
                      <Link onClick={toggleDropdown} to="/quests" className="block px-4 py-3 text-white w-full text-left">Quests</Link>
                    </div>
                    <div>
                      <Link onClick={toggleDropdown} to="/my-points" className="block px-4 py-3 text-white w-full text-left">My Points</Link>
                    </div>
                    <div className="block md:hidden block px-4 py-3 text-white w-full text-left">
                      {!isConnected ? (
                        <button className='btn mobile-wallet' onClick={connectWallet}>Connect Wallet</button>
                      ) : (<w3m-button />)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}